import React, { useContext, useState } from 'react';
import {
  styled, useTheme, AppBar, Typography,
  Stack, Box, Button, Divider, Paper,
  IconButton, Menu, MenuItem, Tooltip, Avatar,
  SwipeableDrawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, 
} from '@mui/material';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material'
import { useNavigate, useLocation } from 'react-router-dom';

import ContextApp from '../../contexts/ContextApp';
import { isAuthenticated, logout } from '../helpers/authentication';

const NavigationButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  '&:hover': {
    color: theme.palette.secondary.main,
    backgroundColor: '#FFF',
  },
}));

const NavBarLeftMenuButton: React.FC<{setLeftDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>}> = ({setLeftDrawerOpen}) => {
  return (
    <Box
      // bgcolor='skyblue'
      flex={1}
      // p={2}
    >
      <IconButton
        color='primary'
        sx={{
          display: {
            xs: 'block',
            md: 'none', // Medium screen or bigger, display block
          },
        }}
        onClick={() => { setLeftDrawerOpen(true) }}
      >
        <MenuIcon />
      </IconButton>
    </Box>
  );
};
const NavBarTitle: React.FC = () => {
  return (
    <Box
      flex={3}
    >
      <div style={{
          display:'flex',justifyContent:'center',alignItems:'center'
          }}>
        <Box
          component="img"
          justifyItems='center'
          sx={{
            height: 45,
            width: 45,
            // maxHeight: { xs: 50, md: 50 },
            // maxWidth: { xs: 50, md: 50 },
            display: {
              xs: 'flex',
              md: 'none', // Small screen or bigger, display block
            },
          }}
          alt="logo"
          src="/JNCLINELOGO.jpg"
        />
      </div>
      <Typography
        color='primary'
        variant='h4'
        fontSize='35px'
        fontWeight='bold'
        sx={{
          display: {
            xs: 'none',
            md: 'block', // Small screen or bigger, display block
          },
          textAlign: 'center',
        }}
      >
        JNC LOGISTICS (S) PTE LTD
      </Typography>
    </Box>
  );
};
const NavBarRightMenuButtons: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const contextApp = useContext(ContextApp);
  const authenticated = isAuthenticated(contextApp);

  return (
    <Box
      flex={1}
      sx={{
        textAlign: 'right',
      }}
    >
      {
        authenticated ? (
          <ProfileIcon />
        ) : (
          <Button
            sx={{
              color: theme.palette.secondary.main,
              fontWeight: 'bold',
            }}
            onClick={() => {
              navigate('/login');
            }}
          >
            <Typography>Login</Typography>
            <AccountCircle sx={{ paddingLeft: '10px' }} />
          </Button>
        )
      }
    </Box>
  );
};

const NavBar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const contextApp = useContext(ContextApp);
  const authenticated = isAuthenticated(contextApp);
  const { pathname } = useLocation();
  const [leftDrawerOpen, setLeftDrawerOpen] = useState<boolean>(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const highlightItem = (url: string): boolean => {
    if (url === '/') return pathname === '/';
    return pathname.startsWith(url);
  };

  return (
    <AppBar
      position='sticky'
      // bgcolor='white'
      sx={{ bgcolor: 'white' }}
    >
      <Box p={2}>
        <Stack
          direction='row'
          // spacing={2}
          justifyContent='space-between'
          // justifyContent='center'
          alignItems='center'
        >
          <NavBarLeftMenuButton setLeftDrawerOpen={setLeftDrawerOpen} />
          <LeftDrawer
            leftDrawerOpen={leftDrawerOpen}
            setLeftDrawerOpen={setLeftDrawerOpen}
          />
          <NavBarTitle />
          <NavBarRightMenuButtons />
        </Stack>
      </Box>
      <Stack
        direction='row'
        spacing={3}
        // justifyContent='space-between'
        justifyContent='center'
        sx={{
          display: {
            xs: 'none',
            md: 'flex', // Small screen or bigger, display block
          },
        }}
      >
        <NavigationButton sx={{ color: highlightItem('/') ? theme.palette.secondary.main : 'primary.main' }} onClick={() => navigate('/')}>HOME</NavigationButton>
        <NavigationButton sx={{ color: highlightItem('/about') ? theme.palette.secondary.main : 'primary.main' }}>ABOUT US</NavigationButton>
        <NavigationButton sx={{ color: highlightItem('/services') ? theme.palette.secondary.main : 'primary.main' }}>OUR SERVICES</NavigationButton>
        <NavigationButton sx={{ color: highlightItem('/schedule') ? theme.palette.secondary.main : 'primary.main' }}>SAILING SCHEDULE</NavigationButton>
        <NavigationButton sx={{ color: highlightItem('/news') ? theme.palette.secondary.main : 'primary.main' }}>NEWS</NavigationButton>
        <NavigationButton sx={{ color: highlightItem('/contact') ? theme.palette.secondary.main : 'primary.main' }}>CONTACT US</NavigationButton>
        {
          authenticated ? (
            <NavigationButton
              sx={{
                color: highlightItem('/dashboard') ? theme.palette.secondary.main : 'primary.main',
              }}
              onClick={handleOpenUserMenu}
            >
              My Dashboard
            </NavigationButton>
          ) : null
        }
      </Stack>
      {
        authenticated ? (
          <DashboardMenuItems
            anchorElUser={anchorElUser}
            setAnchorElUser={setAnchorElUser}
          />
        ) : null
      }
    </AppBar>
  );
};

export default NavBar;

const ProfileIcon = () => {
  const { authState } = useContext(ContextApp);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Button
        sx={{
          color: 'secondary.main',
          fontWeight: 'bold',
        }}
        onClick={(event) => {
          handleOpenUserMenu(event);
        }}
      >
        <Typography>{authState.username}</Typography>
        <AccountCircle sx={{ paddingLeft: '10px' }} />
      </Button>
      <DashboardMenuItems
        anchorElUser={anchorElUser}
        setAnchorElUser={setAnchorElUser}
      />
    </Box>
  );
};

interface DashboardMenuItemsInterface {
  anchorElUser: null | HTMLElement;
  setAnchorElUser: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

const DashboardMenuItems: React.FC<DashboardMenuItemsInterface> = ({
  anchorElUser,
  setAnchorElUser,
}) => {
  const navigate = useNavigate();
  const { authDispatch } = useContext(ContextApp);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem
        onClick={() => {
          handleCloseUserMenu();
          navigate('/dashboard');
        }}
      >
        <Typography textAlign="center">My Shipping Dashboard</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleCloseUserMenu();
          navigate('/dashboard/warehouse');
        }}
      >
        <Typography textAlign="center">My Shipping Address</Typography>
      </MenuItem>
      <MenuItem
      >
        <Typography textAlign="center">My Profile</Typography>
      </MenuItem>
      <MenuItem
        onClick={async () => {
          await logout(authDispatch);
          localStorage.clear();
        }}
      >
        <Typography textAlign="center">Logout</Typography>
      </MenuItem>
    </Menu>
  );
};

interface LeftDrawerInterface {
  leftDrawerOpen: boolean;
  setLeftDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftDrawer: React.FC<LeftDrawerInterface> = ({
  leftDrawerOpen,
  setLeftDrawerOpen,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const contextApp = useContext(ContextApp);
  const authenticated = isAuthenticated(contextApp);

  const setDrawer = (res: boolean) => {
    setLeftDrawerOpen(res);
  };
  return (
    <SwipeableDrawer
      anchor='left'
      open={leftDrawerOpen}
      onClose={() => setDrawer(false)}
      onOpen={() => setDrawer(true)}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        // onClick={setDrawer(false)}
        // onKeyDown={setDrawer(false)}
        // component='div'
      >
        <div
          style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}
        >
          <Box
            component="img"
            justifyItems='center'
            sx={{
              height: 45,
              width: 45,
              // maxHeight: { xs: 50, md: 50 },
              // maxWidth: { xs: 50, md: 50 },
              display: {
                xs: 'flex',
                md: 'none', // Small screen or bigger, display block
              },
            }}
            alt="logo"
            src="/JNCLINELOGO.jpg"
            p={2}
          />
        </div>
        <Typography
          color='primary'
          variant='h4'
          fontSize='15px'
          fontWeight='bold'
          sx={{
            // display: {
            //   xs: 'none',
            //   md: 'block', // Small screen or bigger, display block
            // },
            textAlign: 'center',
          }}
        >
          JNC LOGISTICS (S) PTE LTD
        </Typography>
        {
          authenticated ? (
            <>
              <List>
                <ListItem key={'drawer-item-dashboard'} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate('/dashboard');
                      setDrawer(false);
                    }}
                  >
                    <ListItemText primary='My Shipping Dashboard' />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'drawer-item-warehouse'} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate('/dashboard');
                      setDrawer(false);
                    }}
                  >
                    <ListItemText primary='Warehouse' />
                  </ListItemButton>
                </ListItem>
                <ListItem key={'drawer-item-logout'} disablePadding>
                  <ListItemButton
                    onClick={async () => {
                      await logout(contextApp.authDispatch);
                      localStorage.clear();
                      setDrawer(false);
                    }}
                  >
                    <ListItemText primary='LOGOUT' />
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          ) : (
            <List>
              <ListItem key={'drawer-item-contact'} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate('/login');
                    setDrawer(false);
                  }}
                >
                  <ListItemIcon>
                    <AccountCircle color='secondary' />
                  </ListItemIcon>
                  <ListItemText
                    primary='LOGIN'
                    sx={{ color: 'secondary.main' }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          )
        }
        <Divider
          sx={{
            // color: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
            // width: '10%',
            // minWidth: '30px',
            height: '0.8px',
          }}
        />
        <List>
          <ListItem key={'drawer-item-home'} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('/');
                setDrawer(false);
              }}
            >
              <ListItemText primary='HOME' />
            </ListItemButton>
          </ListItem>
          <ListItem key={'drawer-item-aboutus'} disablePadding>
            <ListItemButton>
              <ListItemText primary='ABOUT US' />
            </ListItemButton>
          </ListItem>
          <ListItem key={'drawer-item-services'} disablePadding>
            <ListItemButton>
              <ListItemText primary='OUR SERVICES' />
            </ListItemButton>
          </ListItem>
          <ListItem key={'drawer-item-schedule'} disablePadding>
            <ListItemButton>
              <ListItemText primary='SAILING SCHEDULE' />
            </ListItemButton>
          </ListItem>
          <ListItem key={'drawer-item-news'} disablePadding>
            <ListItemButton>
              <ListItemText primary='NEWS' />
            </ListItemButton>
          </ListItem>
          <ListItem key={'drawer-item-contact'} disablePadding>
            <ListItemButton>
              <ListItemText primary='CONTACT US' />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </SwipeableDrawer>
  );
};
