import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography, Box, Grid, Button, styled, Stack,
  Paper, Tooltip, IconButton, Chip,
  Table, TableContainer, TableBody, TableHead, TableRow, TableCell, TableFooter, TablePagination,
  Card, CardHeader, CardContent, Collapse, IconButtonProps, Divider,
  Tabs, Tab,
} from '@mui/material';

import ContextApp from '../../../contexts/ContextApp';
import useFetchUserParcelsPaginated from './hooks/useFetchUserParcelsPaginated';
import { DateTimeCom, DateTimeSingleLine } from '../../utility/DateTimeHelpers';
import { findParcelsByModule, getUploadById } from '../../feathers/services/Upload.service';
import { toBlob } from '../../utility/forms/UploadFileField';
import { DirectionsBoat } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Parcel, Workspace, Upload } from '../../feathers/server-interface';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { authState } = useContext(ContextApp);
  const { userParcels, loadMore } = useFetchUserParcelsPaginated(authState._id);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [listType, setListType] = useState< 'parcels' | 'shipments' >('parcels');

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    if (
      (((newPage + 1) * rowsPerPage) > userParcels.data.length)
      // && ((newPage + 1) * rowsPerPage <)
      && (userParcels.data.length < userParcels.total)
    ) await loadMore((newPage + 1) * page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (
      userParcels.data.length < ((parseInt(event.target.value, 10) * page))
    ) await loadMore(rowsPerPage * page);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, padding: '20px 10px 0px 10px' }}>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={8}>
            <Typography variant='h5' sx={{ marginBottom: '10px' }} fontWeight='bold' display='inline'>
              Shipping Dashboard
            </Typography>
            {/* <Divider orientation='vertical' sx={{ height: '3rem', }} /> */}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            {/* <Link to='CreateParcel'> */}
            <Button
              variant='outlined'
              onClick={() => {
                navigate('CreateParcel');
              }}
              color='secondary'
              size='small'
            >
              Submit New Parcel
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ margin: '5px 0 5px 0', borderBottomWidth: '2px' }} />
          <Box
            sx={{
              backgroundColor: '#ededed',
              width: 'fit-content',
              padding: 0.5,
              borderRadius: '0.5rem',
            }}
          >
            <Button
              sx={{
                height: '1.5rem',
                backgroundColor: listType === 'parcels' ? '#FFFFFF' : '',
                '&:hover': {
                  backgroundColor: '',
                },
              }}
              onClick={() => setListType('parcels')}
            >
              My Parcels
            </Button>
            <Button
              sx={{
                height: '1.5rem',
                backgroundColor: listType === 'shipments' ? '#FFFFFF' : '',
              }}
              onClick={() => setListType('shipments')}
            >
              My Shipments
            </Button>
          </Box>
        <Divider sx={{ margin: '5px 0 0 0', borderBottomWidth: '2px' }} />
        {/* Table Starts Here */}
        <TableContainer component={Paper} variant='outlined' sx={{ margin: '5px 0 20px 0' }}>
          <Table aria-label="collapsible table" size='small'>
            <TableHead>
              <TableRow>
                <TableCell width={'3%'} />
                <TableCell width={'18%'}>
                  <Typography variant='caption' fontWeight='bold'>JNC Ref</Typography>
                  {/* <br />
                  <Typography variant='caption'>Submit Date</Typography> */}
                </TableCell>
                <TableCell width={'30%'}>
                  <Typography variant='caption' fontWeight='bold'>Tracking number</Typography>
                </TableCell>
                <TableCell width={'19%'}>
                  <Typography variant='caption' fontWeight='bold'>From</Typography>
                </TableCell>
                <TableCell width={'19%'}>
                  <Typography variant='caption' fontWeight='bold'>To</Typography>
                </TableCell>
                <TableCell width={'10%'} align='right'>
                  <Typography variant='caption' fontWeight='bold'>Shipment</Typography>
                </TableCell>
                <TableCell width={'3%'} align='right'/>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                userParcels?.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((parcel: Parcel, key: number) => (
                  <Fragment key={`parcel-tab-${key}`}>
                    <ParcelsTableRow parcel={parcel} />
                  </Fragment>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={7}
                  // rowsPerPageOptions={[2, 4, 6]}
                  rowsPerPageOptions={[]}
                  labelRowsPerPage=''
                  count={userParcels?.total || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default Dashboard;

const ParcelsTableRow: React.FC<{ parcel: Parcel }> = ({
  parcel,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, ...(open ? {backgroundColor: '#EDEDED'} : {}) }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant='body2' fontWeight='bold' color='secondary'>JNC2023913832</Typography>
          {DateTimeSingleLine(parcel.createInfo?.dateTime as Date)}
        </TableCell>
        <TableCell>
          <Typography variant='body2' fontWeight='bold'>{parcel.trackingNumber}</Typography>
          <Typography variant='caption'>{parcel.courierCompany}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2' fontWeight='bold'>{(parcel.forwardingWarehouse as Workspace).name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2' fontWeight='bold'>{(parcel.destinationWarehouse as Workspace).name}</Typography>
        </TableCell>
        <TableCell align='right'>
          <Typography variant='caption'>-</Typography>
        </TableCell>
        <TableCell align='right'>
          <IconButton
            onClick={() => {
              navigate(`editparcel/${parcel._id}`);
            }}
            size='small'
          >
            <Tooltip title='Edit'>
              <EditIcon fontSize='small' />
            </Tooltip>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} sx={{ ...(open ? {backgroundColor: '#EDEDED'} : {}) }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container>
              <Grid item xs={12} md={2}>
                <Box width='100%' textAlign='center'>
                  <DirectionsBoat sx={{ fontSize: '100px' }} color='primary' />
                  <Typography variant='body2' fontWeight='bold' color='secondary'>ARRIVED</Typography>
                  <Typography>{DateTimeSingleLine(new Date)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={10}>
              {/* <Box sx={{ bgcolor: 'background.paper', width: 500 }}> */}
                <Box sx={{ width: '100%', bgcolor: 'background.paper', marginBottom: 1 }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" textColor='secondary' indicatorColor='secondary'>
                      <Tab label="Detail" {...a11yProps(0)} />
                      <Tab label="Documents" {...a11yProps(1)} />
                      <Tab label="History" {...a11yProps(2)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Typography variant='caption'>Parcel Value:</Typography>
                          <Typography variant='body2'>$ {parcel.parcelValue}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant='caption'>Sensitive Cargo:</Typography>
                          <Typography variant='body2' color={parcel.sensitiveCargo ? 'red' : 'primary'}>{parcel.sensitiveCargo ? 'Yes' : 'No'}</Typography>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <DisplayUploadedDocuments
                        parcelId={parcel._id}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                      Parcel History here
                    </TabPanel>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

interface DisplayUploadedDocumentsInterface {
  parcelId: string;
}

const DisplayUploadedDocuments: React.FC<DisplayUploadedDocumentsInterface> = ({
  parcelId,
}) => {
  const [emptyUploads, setEmptyUploads] = useState<Upload[]>([]);
  useEffect(() => {
    const getEmptyUploads = async () => {
      try {
        const foundEmptyUploads = await findParcelsByModule('parcels', parcelId, true);
        setEmptyUploads(foundEmptyUploads);
      } catch (error) {
        console.log('error: ', error);
      }
    };
    getEmptyUploads();
  }, [parcelId]);

  return (
    <Box>
      <Stack direction="row" spacing={1}>
        {
          emptyUploads.map((file) => (
            <Chip
              key={file._id}
              label={file.fileName}
              onClick={async () => {
                const foundUpload = await getUploadById(file._id);
                const blob = await toBlob(foundUpload.uri, foundUpload.fileName, foundUpload.fileType);
                var csvURL = window.URL.createObjectURL(blob);
                var tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', foundUpload.fileName);
                tempLink.click();
              }}
            />
          ))
        }
      </Stack>
    </Box>
  );
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ParcelRowInterface {
  parcel: any;
}

const ParcelRowCard: React.FC<ParcelRowInterface> = ({
  parcel,
}) => {
  const navigate = useNavigate();
  const [rowExpanded, setRowExpanded] = useState<boolean>(false);
  const handleExpandClick = () => setRowExpanded((prevState: boolean) => !prevState);
  return (
    <Card variant='outlined' sx={{ marginBottom: '10px' }}>
      <CardHeader
        action={
          <>
            {/* <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton> */}
            <IconButton
              onClick={() => {
                navigate(`editparcel/${parcel._id}`);
              }}
            >
              <Tooltip title='Edit'>
                <EditIcon />
              </Tooltip>
            </IconButton>
            <ExpandMore
              expand={rowExpanded}
              onClick={handleExpandClick}
              aria-expanded={rowExpanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </>
        }
        title={(
          <>
            <Grid container>
              <Grid item xl={2} md={2} xs={2}>
                <Typography variant='caption'>Created On:</Typography>
                <Typography>{DateTimeCom(parcel.createInfo.dateTime)}</Typography>
              </Grid>
              <Grid item xl={2} md={2} xs={2}>
                <Typography variant='caption'>Forwarding Warehouse:</Typography>
                <Typography>To: {parcel.forwardingWarehouse.name}</Typography>
              </Grid>
              {/* <Grid item xl={2} md={2} xs={2}>
                <Typography variant='caption'>Destination Warehouse:</Typography>
                <Typography>To: {parcel.destinationWarehouse.name}</Typography>
              </Grid> */}
              <Grid item xl={6} md={6} xs={6}>
                <Typography variant='caption'>Tracking Number:</Typography>
                <Typography>
                  Tracking Number: {parcel.trackingNumber} ({parcel.courierCompany})
                </Typography>
              </Grid>
            </Grid>
          </>
          // <Grid container sx={{  }}>
          //   <Grid item>{DateTimeCom(parcel.createInfo.dateTime)}</Grid>
          //   <Grid item>
          //     <Typography variant='body1' sx={{overflowWrap: 'break-word'}}>
          //       {parcel.trackingNumber}
          //     </Typography>
          //     <Typography variant='caption'>
          //       {parcel.courierCompany}
          //     </Typography>
          //   </Grid>
          //   <Grid item>{parcel.forwardingWarehouse.name}</Grid>
          // </Grid>
        )}
        // subheader="September 14, 2016"
      />
      <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          {parcel.remarks}
        </CardContent>
      </Collapse>
    </Card>
  );
};

const ParcelRow: React.FC<ParcelRowInterface> = ({
  parcel,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  return (
    <>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ background: 'white' }}>
          {DateTimeCom(parcel.createInfo.dateTime)}
        </TableCell>
        <TableCell sx={{ background: 'white'  }}>
          <Typography variant='body1' sx={{overflowWrap: 'break-word'}}>
            {parcel.trackingNumber}
          </Typography>
          <Typography variant='caption'>
            {parcel.courierCompany}
          </Typography>
        </TableCell>
        <TableCell sx={{ background: 'white'  }}>
          {parcel.forwardingWarehouse}
        </TableCell>
        <TableCell sx={{ background: 'white'  }}>
          {parcel.remarks}
        </TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          <IconButton
            onClick={() => {
              navigate(`editparcel/${parcel._id}`);
            }}
          >
            <Tooltip title='Edit'>
              <EditIcon />
            </Tooltip>
          </IconButton>
        </TableCell>
        <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
        </TableRow>
      {/* </TableRow> */}
    </>
  );
};
