import { useEffect, useState } from 'react';
import { Paginated } from '@feathersjs/feathers';
import { findParcelsByUserPaginated } from '../../../feathers/services/Parcel.service';
// import { Shelve } from '../../../../server-interface';

const useFetchUserParcelsPaginated= (userId: string): any => {
  const [userParcels, setUserParcels] = useState<Paginated<any>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const fetchedUserParcels = await findParcelsByUserPaginated(userId, 0);
        setUserParcels(fetchedUserParcels); 
      } catch (error: any) {
        setError(error.message);
      }
      setIsLoading(false);
    }
    init();
  }, [userId]);

  const loadMore = async () => {
    const currentTotal = userParcels?.total || 0;
    const currentSkip = userParcels?.skip || 0;
    const difference = currentTotal - currentSkip;
    const newSkipValue = difference < 10 ? currentSkip + difference :  currentSkip + 10;

    if (difference !== 0) {
      const fetchedUserParcels = await findParcelsByUserPaginated(userId, newSkipValue);
      if (!userParcels) setUserParcels(fetchedUserParcels);
      if (userParcels) {
        setUserParcels((prevState: any) => {
          return {
            ...prevState,
            data: [...prevState.data, ...fetchedUserParcels.data],
            skip: fetchedUserParcels.skip,
            total: fetchedUserParcels.total,
          }
        });
      }
    }
  }

  return {
    userParcels,
    loadMore,
    isLoading,
    error,
  };
}

export default useFetchUserParcelsPaginated;
