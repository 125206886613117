import React from 'react';
import { Typography } from '@mui/material';

const SampleForm: React.FC = () => {

  return (
    <div>
      <Typography variant='h1'>Typography variant h1</Typography>
      <Typography variant='h2'>Typography variant h2</Typography>
      <Typography variant='h3'>Typography variant h3</Typography>
      <Typography variant='h4'>Typography variant h4</Typography>
      <Typography variant='h5'>Typography variant h5</Typography>
      <Typography variant='h6'>Typography variant h6</Typography>
      <Typography variant='subtitle1'>Typography variant subtitle1</Typography>
      <Typography variant='subtitle2'>Typography variant subtitle2</Typography>
      <Typography variant='body1'>Typography variant body1</Typography>
      <Typography variant='body2'>Typography variant body2</Typography>
      <Typography variant='button'>Typography variant button</Typography>
      <br />
      <Typography variant='caption'>Typography variant caption</Typography>
      <br />
      <Typography variant='overline'>Typography variant overline</Typography>
    </div>
  );
};

export default SampleForm;
