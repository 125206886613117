import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import { styled, Button, Box, Stack } from '@mui/material';
import Samples from './Samples';
import SampleForm from './components/SampleForm';
import SampleFlex from './components/SampleFlex';
import SampleTypography from './components/SampleTypography';

const SampleViewer =  () => {
  const BlueButton = styled(Button)(({ theme })=>({
    // backgroundColor: 'skyblue',
    backgroundColor: theme.palette.secondary.main,
    color: '#888',
    margin: 5,
    '&:hover': {
      backgroundColor: 'lightblue',
    },
    '&disabled': {
      backgroundColor: 'gray',
      color: 'white',
    },
  }));
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Link to='flex'>
        <BlueButton>Flex Stylings</BlueButton>
      </Link>
      <Link to='form'>
        <Button>Form</Button>
      </Link>
      <Link to='typography'>
        <Button variant='contained' color='secondary'>Typography</Button>
      </Link>
      <Link to='buttons'>
        <BlueButton disabled>My disabled Button</BlueButton>
        <BlueButton>My Button</BlueButton>
      </Link>
      <Routes>
        <Route
          path='flex'
          element={<SampleFlex />}
        />
        <Route
          path='form'
          element={<SampleForm />}
        />
        <Route
          path='typography'
          element={<SampleTypography />}
        />
      </Routes>
    </div>
  );
};

export default SampleViewer;
