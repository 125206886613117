import { Select, SelectProps } from 'formik-mui';
import { MenuItem, Typography } from '@mui/material';

interface OptionInterface {
  value: string;
  label: string;
}

interface FormTextFieldInterface extends SelectProps {
  options: OptionInterface[];
}

const FormSelect = (props: FormTextFieldInterface) => {
  const { required, options, ...rest } = props;
  const generatedOptions = () => options.map((option) => (
    <MenuItem
      key={`MenuOption-${option.value}-${option.label}`} value={option.value}
    >
      {/* <Typography> */}
        {option.label}
      {/* </Typography> */}
    </MenuItem>
  ));

  return (
    <>
      <Select
        variant='outlined'
        formControl={{ sx: { width: '100%', marginBottom: 2 } }}
        // formHelperText={{ children: 'How old are you?' }}
        // inputLabel={{
        //   shrink: true,
        // }}
        size='small'
        {...rest}
      >
        {generatedOptions()}
      </Select>
    </>
  );
};

export default FormSelect;
