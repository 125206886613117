import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box, Typography, Button, Grid,
  InputAdornment, Stack, Divider,
} from '@mui/material';
import { DirectionsBoat, ArrowDropDown } from '@mui/icons-material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import useFetchWarehousesByType from './hooks/useFetchWarehousesByType';
import ContextApp from '../../../contexts/ContextApp';
import FormTextField from '../../utility/forms/FormTextField';
import FormSelect from '../../utility/forms/FormSelect';
import FormSwitch from '../../utility/forms/FormSwitch';
import UploadFileField, { toBase64, uploadsToBlob, toBlob, CustomFileType } from '../../utility/forms/UploadFileField';
import { createParcel, patchParcel, getParcel } from '../../feathers/services/Parcel.service';
import { createUpload, findParcelsByModule, removeUpload } from '../../feathers/services/Upload.service';
import { Upload, User } from '../../feathers/server-interface';

const DashboardParcelForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { authState } = useContext(ContextApp);
  const { warehouses: forwardingWarehouses } = useFetchWarehousesByType('forwarding');
  const { warehouses: destinationWarehouses } = useFetchWarehousesByType('destination');
  const [selectedParcel, setSelectedParcel] = useState<any>();
  const [initialFiles, setInitialFiles] = useState<Upload[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<CustomFileType[]>([]);
  const [initialized, setInitialzed] = useState<boolean>(false);
  const forwardingWarehousesOptions = forwardingWarehouses.map((warehouse) => ({ value: warehouse._id, label: warehouse.name }));
  const destinationWarehousesOptions = destinationWarehouses.map((warehouse) => ({ value: warehouse._id, label: warehouse.name }));

  useEffect(() => {
    const findParcel = async () => {
      try {
        if (id) {
          const foundParcel = await getParcel(id);
          if (!foundParcel) navigate('/dashboard');
          if ((foundParcel.client as User)._id === authState._id) setSelectedParcel(foundParcel);
        }
      } catch (error) {
        navigate('/dashboard');
        console.log(error);
      }
    };
    const findInitialUploads = async () => {
      try {
        if (id) {
          const foundUploads = await findParcelsByModule('parcels', id);
          console.log('foundUploads: ', foundUploads);
          const blobUploads = foundUploads ? await uploadsToBlob(foundUploads) : [];
          setInitialFiles(foundUploads);
          setUploadedFiles(blobUploads);
          console.log('blobUploads: ', blobUploads);
        }
      } catch (error: any) {
        console.log(error.message);
      } finally {
        setInitialzed(true);
      }
    };
    findParcel();
    findInitialUploads();
  }, [id, authState._id, navigate]);

  const schema = Yup.object().shape({
    forwardingWarehouse: Yup.string().required('Forwarding warehouse is required'),
    destinationWarehouse: Yup.string().required('Destination warehouse is required'),
    courierCompany: Yup.string().required('Courier company is required'),
    trackingNumber: Yup.string().required('Tracking number is required'),
    parcelValue: Yup.number().typeError("Must be a number.")
      .positive('Negative numbers not allowed.').required('Parcel value is required'),
    remarks: Yup.string().test(
      "len",
      "Maximum 200 characters",
      (val) => {
        if (val === undefined) {
          return true;
        }
        // return !!(val.length =< 5);
        return val.length === 0 || (val.length <= 200);
      }
    ),
  });

  return (
    <Box sx={{ flexGrow: 1, padding: '10px' }}>
      <Typography variant='h5' fontWeight='bold' sx={{ padding: '10px 0px 10px 0px'}}>
        {id ? 'Edit' : 'Submit New'} Parcel
      </Typography>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={{
          client: authState._id,
          forwardingWarehouse: selectedParcel?.forwardingWarehouse._id || '',
          destinationWarehouse: selectedParcel?.destinationWarehouse?._id || '',
          courierCompany: selectedParcel?.courierCompany || '',
          trackingNumber: selectedParcel?.trackingNumber || '',
          parcelValue: selectedParcel?.parcelValue || '',
          sensitiveCargo: selectedParcel?.sensitiveCargo || false,
          remarks: selectedParcel?.remarks || '',
          uploadsTouched: false,
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(false);
          try {
            if (id && selectedParcel) {
              const { client, ...rest } = values;
              // Patch Parcel
              await patchParcel(id, rest);
              // Compare Files -> Remove Files, Create Files
              // Files to add
              const filesToAdd = uploadedFiles.filter((file) => !file._id);
              // console.log('filesToAdd: ', filesToAdd);
              const filedatas = await Promise.all(filesToAdd.map(async (file) => ({
                module: 'parcels',
                belongTo: id,
                fileName: file.name,
                fileSize: (file.size || '').toString(),
                fileType: file.type,
                uri: await Promise.resolve(toBase64(file)) as string,
              }))) as Omit<Upload, '_id'>[];
              for await (const filedata of filedatas) {
                await createUpload(filedata);
              }
              // Files to remove
              const filesToRemove = [];
              const initialFilesIds = initialFiles.map((initialFile) => initialFile._id);
              const uploadedFilesIds = uploadedFiles.map((uploadedFile) => uploadedFile._id);
              for (let i = 0; i < initialFilesIds.length; i++) {
                const target = initialFilesIds[i];
                const stillExists = uploadedFilesIds.includes(target);
                if (!stillExists) filesToRemove.push(target);
              }
              for await (const fileToRemove of filesToRemove) {
                await removeUpload(fileToRemove);
              }
              // console.log('fileToRemove: ', filesToRemove);
              // console.log('initialFilesIds: ', initialFilesIds);
              // console.log('uploadedFilesIds: ', uploadedFilesIds);
              // console.log('filesToRemove: ', filesToRemove);
            } else {
              // Create Parcel
              const createdParcel = await createParcel(values);
              const filedatas = await Promise.all(uploadedFiles.map(async (file) => ({
                module: 'parcels',
                belongTo: createdParcel._id,
                fileName: file.name,
                fileSize: (file.size || '').toString(),
                fileType: file.type,
                uri: await Promise.resolve(toBase64(file)) as string,
              }))) as Omit<Upload, '_id'>[];
              for await (const filedata of filedatas) {
                await createUpload(filedata);
              }
            }
            navigate('/dashboard');
          } catch (error) {
            console.log('error: ', error);
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          errors,
          submitForm,
          dirty,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          setTouched,
          resetForm,
        }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xl={6} md={6} xs={12}>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }} paddingBottom={1}>
                    <ArrowDropDown fontSize='large' color='secondary' />
                    <Typography variant='h5'>
                      Route Information
                    </Typography>
                  </Box>
                  <Stack
                    direction='row'
                    // spacing={2}
                    // justifyContent='space-between'
                    // justifyContent='center'
                    sx={{
                      // backgroundColor: 'green',
                    }}
                  >
                    <Box flex={0.08} sx={{ backgroundColor: '' }}>
                      <Box
                        sx={{
                          position: "relative",
                          width: "1.5px",
                          height: "20px",
                          backgroundColor: "primary.main",
                          "&::after": {
                            content: "''",
                            position: "absolute",
                            top: 0,
                            left: "50%",
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor: "secondary.main",
                            transform: "translate(-50%, -50%)",
                          },
                          margin: '10px 0 5px 12px'
                        }}
                      />
                      <DirectionsBoat fontSize='small' color='secondary' sx={{ margin: '0px 0px 0px 2.8px' }} />
                      <Box
                        sx={{
                          position: "relative",
                          width: "1.5px",
                          height: "20px",
                          backgroundColor: "primary.main",
                          // "&::before": {
                          //   content: "''",
                          //   position: "absolute",
                          //   top: 0,
                          //   bottom: "8px", // height of dot + space
                          //   left: "50%",
                          //   width: "1px",
                          //   backgroundColor: "red",
                          // },
                          "&::after": {
                            content: "''",
                            position: "absolute",
                            bottom: 0,
                            left: "50%",
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor: "secondary.main",
                            transform: "translate(-50%, 50%)",
                          },
                          margin: '0 0 12px 12px'
                        }}
                      />
                    </Box>
                    <Box flex={1} sx={{  }}>
                      <Field
                        component={FormSelect}
                        name="forwardingWarehouse"
                        label="Forwarding Warehouse"
                        options={forwardingWarehousesOptions}
                        inputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                      <Field
                        component={FormSelect}
                        name="destinationWarehouse"
                        label="Destination Warehouse"
                        options={destinationWarehousesOptions}
                      />
                    </Box>
                  </Stack>
                </Box>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }} paddingBottom={1}>
                    <ArrowDropDown fontSize='large' color='secondary' />
                    <Typography variant='h5'>
                      Parcel Information
                    </Typography>
                  </Box>
                  <Field
                    component={FormTextField}
                    name='courierCompany'
                    type='text'
                    label='Courier Company'
                    required
                  />
                  <Field
                    component={FormTextField}
                    name='trackingNumber'
                    type='text'
                    label='Tracking Number'
                    required
                  />
                  <Field
                    component={FormTextField}
                    name='parcelValue'
                    type='number'
                    label='Parcel value'
                    required
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  <div style={{ paddingRight: '10px' }}>
                    <Field
                      component={FormSwitch}
                      name='sensitiveCargo'
                      type='checkbox'
                      label='Sensitive Cargo'
                      required
                    />
                  </div>
                  <Field
                    component={FormTextField}
                    name='remarks'
                    type='text'
                    label='Remarks/ Goods Description'
                    multiline
                    maxRows={5}
                    // variant='outlined'
                    rows={3}
                  />
                </Box>
              </Grid>

              <Grid item xl={6} md={6} xs={12}>
                {/* <DropzoneArea
                  onChange={(files) => {
                    setUploadedFiles(files);
                  }}
                  fileObjects={[]}
                  acceptedFiles={["image/jpeg", "image/png", "image/bmp", "application/pdf"]}
                  showFileNamesInPreview={true}
                  showFileNames={true}
                  initialFiles={[
                    // `data:image/png;base64,${sample}`
                  ]}
                  filesLimit={3}
                  // maxFileSize={}
                /> */}
                {
                  initialized ? (
                    <>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ArrowDropDown fontSize='large' color='secondary' />
                        <Typography variant='h5'>
                          Documents
                        </Typography>
                      </Box>
                      <UploadFileField
                        label='Upload documents (Invoice etc.)'
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  ) : null
                }
              </Grid>
            </Grid>
            <Button
              variant='contained'
              sx={{ width: '100%', marginBottom: '10px' }}
              type='submit'
              disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
            >
              Submit
            </Button>
            <Button
              variant='outlined'
              sx={{ width: '100%' }}
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Back
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DashboardParcelForm;
