import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ThemeProvider, createTheme, responsiveFontSizes,
} from '@mui/material';
import LoadingSpinner from '../utility/LoadingSpinner';

import NavBar from './NavBar';
import Footer from './Footer';
import PrivateRoute from './PrivateRoute';
import LandingPage from '../pages/landing/LandingViewer';
import DashboardPage from '../pages/dashboard/DashboardViewer';
import SampleViewer from '../pages/sample/SampleViewer';
import LoginPage from '../pages/authentication/LoginPage';
import SignupPage from '../pages/authentication/SignupPage';

const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#003366',
      // main: 'red',
      light: '#EDEDED',
    },
    secondary: {
      main: '#fd7702',
      // main: '#000',
    },
    // otherColor: {
    //   main: '#999',
    // },
  },
  typography: {
    // fontSize: 10,
    allVariants: {
      color: '#003366'
    }
  },
});

const AppRouter: React.FC = () => {
  const theme = responsiveFontSizes(lightTheme);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <NavBar />
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route
              path='/'
              element={<LandingPage />}
            />
            <Route
              path='/login'
              element={<LoginPage />}
            />
            <Route
              path='/signup'
              element={<SignupPage />}
            />
            <Route
              path='/dashboard/*'
              element={
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              }
            />
            <Route
              path='/samples/*'
              element={<SampleViewer />}
            />
            <Route
              path='/*'
              element={<LandingPage />}
            />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;
