import { Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

export const toDateTimeString = (dateTime: Date | any) => format(new Date(dateTime), 'dd/MM/yyyy, hh:mm a');

export const toDateString = (dateTime: Date) => format(new Date(dateTime), 'dd/MM/yyyy');

export const toTimeString = (dateTime: Date) => format(new Date(dateTime), 'hh:mm a');

export const DateTimeCaption = (createdOn: Date, updatedOn: Date) => (
  <Grid container>
    <Grid item xl={6} md={6} xs={12}>
      <Typography
        variant='caption'
      >
        Created on: {toDateTimeString(new Date(createdOn))}
      </Typography>
    </Grid>
    <Grid
      style={{textAlign: 'right'}}
      item
      xl={6}
      md={6}
      xs={12}
    >
      <Typography variant='caption'>
        Updated on: {toDateTimeString(new Date(updatedOn))}
      </Typography>
    </Grid>
  </Grid>
);

export const DateTimeCom = (dateTime: Date) => (
  <>
    <Typography variant='body1' style={{overflowWrap: 'break-word'}}>
      {toDateString(dateTime)}
    </Typography>
    <Typography variant='caption'>
      {toTimeString(dateTime)}
    </Typography>
  </>
);

export const DateTimeSingleLine = (dateTime: Date) => (
  <>
    <Typography variant='caption' display='inline' style={{overflowWrap: 'break-word'}}>
      {toDateString(dateTime)}, &nbsp;
    </Typography>
    <Typography variant='caption'>
      {toTimeString(dateTime)}
    </Typography>
  </>
);
