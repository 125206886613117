import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button, Grid, } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import FormTextField from '../../utility/forms/FormTextField';
import { createUser } from '../../feathers/services/User.service';

const SignupPage: React.FC = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const schema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match'),
  });

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{  
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Formik
            enableReinitialize
            validationSchema={schema}
            initialValues={{
              username: '',
              email: '',
              password: '',
              confirmPassword: '',
            }}
            onSubmit={async (values, actions) => {
              try {
                actions.setSubmitting(true);
                setErrorMessage('');
                if (values.confirmPassword !== values.password) throw new Error('Password do not match');
                const data = {
                  username: values.username,
                  email: values.email,
                  password: values.password,
                };
                await createUser(data);
                // actions.setSubmitting(false);
                navigate('/');
              } catch (error: any) {
                setErrorMessage(error.message as string);
                actions.setSubmitting(false); 
              }
            }}
          >
            {({
              isSubmitting,
              errors,
              submitForm,
              dirty,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setTouched,
              resetForm,
            }) => (
              <Form>
                <Field
                  component={FormTextField}
                  name='username'
                  type='text'
                  label='Username'
                  required
                />
                <Field
                  component={FormTextField}
                  name='email'
                  type='text'
                  label='Email'
                  required
                />
                <Field
                  component={FormTextField}
                  name='password'
                  type='password'
                  label='Password'
                  required
                />
                <Field
                  component={FormTextField}
                  name='confirmPassword'
                  type='password'
                  label='Confirm Password'
                  required
                />
                <Box sx={{ width: '100%', paddingBottom: 1, textAlign: 'center' }}>
                  <Typography variant='caption' color='red'>
                    {errorMessage}
                  </Typography>
                </Box>
                <Button
                  variant='contained'
                  sx={{ width: '100%' }}
                  type='submit'
                  disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
                >
                  SIGN UP
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
        <Grid container>
            <Grid item xs>
              <Button
                variant='text'
                onClick={() => {
                  navigate('/');
                }}
              >
                Home Page
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                onClick={() => {
                  navigate('/login');
                }}
              >
                Already have an account? Login
              </Button>
            </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SignupPage;
