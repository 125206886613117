import React, { Fragment } from 'react';
import {
  Box, Typography, Grid, Stack,
  Button, Divider,
} from '@mui/material';
import { Warehouse, CopyAllOutlined } from '@mui/icons-material';

import useFetchWarehousesByType from './hooks/useFetchWarehousesByType';

const DashboardWarehouseInfo: React.FC = () => {
  const { warehouses } = useFetchWarehousesByType('forwarding');

  const sampleWarehouse = warehouses.map((item) => ({
    address: {
      area: '广东省佛山市南海区里水镇',
      address: '旗峰工业路全顺祥物流基地19栋泽程仓',
      postalcode: '528244',
      name: 'CSL-YFW',
      phoneNumber: '13711275689/18925122768',
      uniqueIdentifider: 'CSL-JWA',
    },
    ...item,
  }));
  return (
    <Box sx={{ width: '100%', paddingBottom: '10vh' }}>
      <div style={{ paddingTop: '15px' }}>
        <Typography variant='h5' sx={{ margin: '10px' }} fontWeight='bold' display='inline'>
          Warehouse Information
        </Typography>
      </div>
      <Grid container spacing={2}>
        {
          sampleWarehouse.map((warehouse: any, key: number) => (
            <Fragment>
              <WarehouseDisplay warehouse={warehouse} />
            </Fragment>
          ))
        }
      </Grid>
    </Box>
  );
};

export default DashboardWarehouseInfo;

interface WarehouseDisplayInterface {
  warehouse: any;
}

const WarehouseDisplay: React.FC<WarehouseDisplayInterface> = ({
  warehouse
}) => {
  return (
    <Grid item xs={12} md={6}>
      <Box sx={{  }} p={1}>
        <Box sx={{ display: "flex", alignItems: "center", backgroundColor: '#EDEDED', borderRadius: 3 }} p={1}>
          <Warehouse sx={{color: 'secondary.main'}} />
          <Typography variant='h5' sx={{ marginLeft: 1 }}>
            {warehouse.name}
          </Typography>
        </Box>
        <WarehouseField title='Recepient' value={warehouse.address.name} />
        <WarehouseField title='Area' value={warehouse.address.area} />
        <WarehouseField title='Address' value={warehouse.address.address} />
        <WarehouseField title='Postal Code' value={warehouse.address.postalcode} />
        <WarehouseField title='Phone Number' value={warehouse.address.phoneNumber} />
      </Box>
    </Grid>
  );
};

interface WarehouseFieldInterface {
  title: string;
  value: string;
}

const WarehouseField: React.FC<WarehouseFieldInterface> = ({
  title,
  value,
}) => {
  return (
    <Box marginBottom={1} marginTop={1}>
      <Typography variant='caption'>{title}</Typography>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='body2'>{value}</Typography>
        <Button
          size='small'
          variant='text'
          sx={{ fontSize: '0.8rem', padding: 0 }}
          onClick={() => {
            navigator.clipboard.writeText(value)
          }}
        >
          <CopyAllOutlined fontSize='small' sx={{ fontSize: '0.8rem' }} />
          Copy
        </Button>
      </Stack>
      <Divider />
    </Box>
  );
};
