
import { paramsForServer } from 'feathers-hooks-common';

import FeathersApp, { Service } from '../Feathers';
import { Workspace } from '../server-interface';

export const workspaceFeathersService: any = FeathersApp.service(Service.Workspace);

export const workplaceFind = (type: 'forwarding' | 'destination'): Promise<Workspace[]> => (
  workspaceFeathersService.find(paramsForServer({
    paginate: false,
    query: {
      // $populate: ['workspaceAdmins', 'workspaceMembers'],
      ...(type === 'forwarding' ? { forwardingWarehouse: true } : { destinationWarehouse: true }),
      $sort: {
        'updateInfo.dateTime': 1,
      },
    },
  }))
);
