import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import { Buffer } from 'buffer';

import { Upload } from '../../../feathers/server-interface';

export interface CustomFileType extends File {
  _id?: String;
}

interface UploadFileFieldInterface {
  label?: string;
  uploadedFiles: CustomFileType[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<CustomFileType[]>>;
  setFieldValue: any;
}

// export const toBase64 = (file: any) => new Promise((resolve, reject) => {
//   const reader = new FileReader();
//   reader.readAsDataURL(file);
//   reader.onload = () => resolve(reader.result);
//   reader.onerror = error => reject(error);
// });

export const toBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader && reader.result) {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      }
    };
    reader.onerror = error => reject(error);
  });
};

export const toBlobT = async (uri: string, filename: string, mimeType: string) => {
  return (fetch(uri)
    .then(function(res){return res.arrayBuffer();})
    .then(function(buf){return new File([buf], filename,{type:mimeType});})
  );
};

export const toBlob = async (uri: string, filename: string, mimeType: string) => {
  const content = Buffer.from(uri, 'base64');
  // const buffer = new ArrayBuffer(content.length);
  // const view = new Uint8Array(buffer);
  // for (let n = 0; n < content.length; n++) {
  //   view[n] = content.charCodeAt(n);
  // }
  const blob = new Blob([content], { type: mimeType });
  // return blob;
  return new File([blob], filename, { lastModified: new Date().getTime(), type: mimeType });
};

export const uploadsToBlob = (uploads: Upload[]) => Promise.all(uploads.map(async (upload) => {
  const blobFile = await toBlob(upload.uri, upload.fileName, upload.fileType);
  const blobFileWithId: CustomFileType = Object.assign(blobFile, { _id: upload._id });
  return blobFileWithId as File;
  // return blobFile;
}));

// export const toBlob = async (upload: Upload) => {
//   const foundUploadsToFile = await fetch(`data:${upload.fileType};base64,${upload.uri}`);
//   const blob = await foundUploadsToFile.blob();
//   return blob;
// };

// export const uploadsToBlob = async (uploads: Upload[]) => uploads.map(async (upload) => await toBlob(upload));

const UploadFileField: React.FC<UploadFileFieldInterface> = ({
  label,
  uploadedFiles,
  setUploadedFiles,
  setFieldValue,
}) => {
  useEffect(() => {
    // console.log('uploadedFiles: ', uploadedFiles);
  }, [uploadedFiles])
  return (
    <div style={{ marginBottom: '10px' }}>
      <Typography variant='body2'>{label ? label : 'Upload Files'}</Typography>
      <DropzoneArea
        onChange={(files) => {
          // console.log('files: ', files);
          setFieldValue('uploadsTouched', true);
          setUploadedFiles(files);
        }}
        fileObjects={[]}
        acceptedFiles={["image/jpeg", "image/png", "image/bmp", "application/pdf"]}
        showFileNamesInPreview={true}
        showFileNames={true}
        initialFiles={uploadedFiles}
        filesLimit={3}
        // maxFileSize={}
      />
    </div>
  );
};

export default UploadFileField;
