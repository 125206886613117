import { Paginated } from '@feathersjs/feathers';

import FeathersApp, { Service } from '../Feathers';
import { Parcel } from '../server-interface';

export const parcelFeathersService: any = FeathersApp.service(Service.Parcel);

export const findParcelsByUserPaginated = (
  userId: string,
  skip: number,
): Promise<Paginated<Parcel>> => (
  parcelFeathersService.find({
    query: {
      client: userId,
      $skip: skip,
      $sort: {
        'updateInfo.dateTime': -1,
      },
    },
  })
);

export const createParcel = (data: Partial<Parcel>): Promise<Parcel> => (
  parcelFeathersService.create(data)
);

export const patchParcel= (productId: string, data: Partial<Parcel>): Promise<Parcel> => (
  parcelFeathersService.patch(productId, data)
);

export const getParcel = (productId: string): Promise<Parcel> => (
  parcelFeathersService.get(productId)
);

// export const removeProduct = (productId: string): Promise<Product> => (
//   productFeathersService.remove(productId)
// );
