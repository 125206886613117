import FeathersApp, { Service } from '../Feathers';
import { paramsForServer } from 'feathers-hooks-common'
import { Upload } from '../server-interface';

export const uploadFeathersService: any = FeathersApp.service(Service.Upload);

export const findParcelsByModule = (
  module: string,
  id: string,
  getEmpty?: boolean,
): Promise<Upload[]> => (
  uploadFeathersService.find(paramsForServer({
    ...(getEmpty ? { getEmpty: true } : {}),
    paginate: false,
    query: {
      module,
      belongTo: id,
    },
  }))
);

export const getUploadById = (id: string): Promise<Upload> => (
  uploadFeathersService.get(id)
);

export const createUpload = (data: Omit<Upload, '_id'>): Promise<Upload> => (
  uploadFeathersService.create(data)
);

// export const getUpload = (): Promise<Upload> => (
//   uploadFeathersService.get('dsfdsf')
// );

export const removeUpload = (id: string): Promise<Upload> => (
  uploadFeathersService.remove(id)
);

// export const createUpload = async (data: Partial<Upload>): Promise<Upload> => {
//   console.log('START IN CREATE UPLOAD!');
//   const result = await uploadFeathersService.create(data);
//   console.log('END IN CREATE UPLOAD!');
//   return result;
// };
