import React, { useEffect, useReducer, useRef } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import ContextApp from './contexts/ContextApp';
import ReducerAuthentication, { initialState } from './reducers/reducerAuthentication';

import './App.css';
import AppRouter from './components/navigation/AppRouter';

const App: React.FC = () => {
  const [authState, authDispatch] = useReducer(ReducerAuthentication, initialState);
  const contextApp = {
    authState,
    authDispatch,
  };

  return (
    <ContextApp.Provider value={contextApp}>
      <AppRouter />
    </ContextApp.Provider>
  );
}

export default App;
