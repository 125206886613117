import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { Place, LocalPhone, Email } from '@mui/icons-material';

const Footer: React.FC = () => {
  return (
    <div style={{ width: '100%', backgroundColor: '#E0ECF5', padding: '30px 0px 60px 0px', paddingBottom: '30px' }}>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} md={4}>
          <Typography variant='h5' color='primary' sx={{ padding: '10px' }}>CONTACT</Typography>
          <Stack
            direction='row'
            // spacing={2}
            // justifyContent='space-between'
            // justifyContent='center'
          >
            <Place color='secondary' sx={{ paddingLeft: '5px' }} />
            <Typography sx={{ paddingLeft: '20px' }}>200 Cantonment Road,</Typography>
          </Stack>
          <Typography sx={{ paddingLeft: '48px' }}>Southpoint, #10-02</Typography>
          <Typography sx={{ paddingLeft: '48px', paddingBottom: '10px' }}>S (089763)</Typography>
          <Stack
            direction='row'
            sx={{ padding: '10px 0' }}
          >
            <LocalPhone color='secondary' sx={{ paddingLeft: '5px' }} />
            <Typography sx={{ paddingLeft: '20px' }}>+65 6887 9700 (Main)</Typography>
          </Stack>
          <Stack
            direction='row'
            sx={{ padding: '10px 0' }}
          >
            <Email color='secondary' sx={{ paddingLeft: '5px' }} />
            <Typography sx={{ paddingLeft: '20px' }}>enquiry@jncline.com.sg</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant='h5' color='primary'>QUICKLINKS</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant='h5' color='primary'>ACCOUNTS</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
