import { Reducer } from 'react';

export interface AuthenticationState {
  _id: string;
  username: string;
  accessToken: string;
}

export const initialState = {
  _id: '',
  username: '',
  accessToken: '',
}

export const AuthenticationStateAction = {
  POPULATE_USER: 'populate-user' as const,
  UPDATE_USER: 'update-user' as const,
  LOGOUT: 'logout' as const,
  // TIMEOUT_LOGOUT: 'timeout-logout' as const,
  // FORCE_LOGOUT: 'force-logout' as const,
}

interface PopulateUserAction {
  type: typeof AuthenticationStateAction.POPULATE_USER;
  payload: {
    _id: string,
    username: string;
    accessToken: string;
  }
}

interface UpdateUserAction {
  type: typeof AuthenticationStateAction.UPDATE_USER;
  payload: {
    username: string;
  }
}

interface LogoutAction {
  type: typeof AuthenticationStateAction.LOGOUT;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AuthenticationStateAction =
  | PopulateUserAction
  | UpdateUserAction
  | LogoutAction

const reducer: Reducer<AuthenticationState, AuthenticationStateAction> = (state, action) => {
  switch (action.type) {
    case AuthenticationStateAction.POPULATE_USER:
      return {
        ...state,
        _id: action.payload._id,
        username: action.payload.username,
        accessToken: action.payload.accessToken,
      };
    case AuthenticationStateAction.UPDATE_USER:
      return {
        ...state,
        username: action.payload.username,
      };
    case AuthenticationStateAction.LOGOUT:
      sessionStorage.clear();
      return initialState;
  }
};

export default reducer;