import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Landing from './Landing';

const LandingViewer: React.FC = (): any => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route
        path='/'
        element={<Landing />}
      />
    </Routes>
  </div>
);

export default LandingViewer;
