import React from 'react';
import {
  useTheme, Box, Typography, Divider, Container, styled,
  Grid, Card, CardMedia, CardContent,
  Stack, Button,
  ImageList, ImageListItem,
} from '@mui/material';
import { ShoppingBasket, Warehouse, LocalShipping } from '@mui/icons-material';
import { useLottie } from 'lottie-react';

import WorldConnection from './assets/WorldConnection.jpg';
import FooterBackground from './assets/global-logistics-disruption-new.jpeg';
// import WarehouseAnimation from "./assets/134464-world-map-with-logistics-animation.json";
import WarehouseAnimation from "./assets/58226-warehouse-delivery.json";
import ContainerAnimation from "./assets/131987-container.json";
import AreaAnimation from "./assets/134464-world-map-with-logistics-animation.json";
import MovingTruckAnimation from "./assets/95433-trukkin-loader.json";// Moving Truck

const Landing: React.FC = () => {
  return (
    <>
      <VideoIntroduction />
      <HowToUseSection />
      <AboutUsTwo />
      <AboutUsSection />
      <OurServicesSection />
      <ContactUsSection />
      <LocationSection />
    </>
  );
};

export default Landing;

const AboutUsTwo: React.FC = () => {
  const options = {
    animationData: WarehouseAnimation,
    loop: true,
    style: {
      height: '100%'
    },
  };
  const containerOptions = {
    animationData: ContainerAnimation,
    loop: true,
    style: {
      height: '180px'
    },
  };
  const areaOptions = {
    animationData: AreaAnimation,
    loop: true,
    style: {
      height: '180px'
    },
  };
  const movingTruckOptions = {
    animationData: MovingTruckAnimation,
    loop: true,
    style: {
      width: '100%',
      // height: '50px',
      viewBox: "0 0 100 100",
    },
  };
  const { View } = useLottie(options);
  const { View: ContainerView } = useLottie(containerOptions);
  const { View: SquareArea } = useLottie(areaOptions);
  const { View: MovintTruckView } = useLottie(movingTruckOptions);
  return (
    <ImageBackground url={WorldConnection} opacity={0.3}>
      <ImageBackgroundContent textAlign="center">
      <Grid container>
        <Grid item xs={12} md={6} p={2}>
          <SectionTitle title='About Us' />
          <Typography color='primary.main'>
            We strive to bring customized solutions to our vast client base. JNC Line commits to develop into a Premier International NVOCC and Logistics Solutions Provider. We continue to invest in innovation processes, technology, training, equipments and other tools of support in order to provide valued added services to suit the ever changing and growing needs of our customers regionally and worldwide.
          </Typography>
          <Stack
            direction='row'
            spacing={10}
            // justifyContent='space-between'
            justifyContent='center'
            // sx={{ padding: '10px'}}
          >
            <AboutUsFeaturesTwo
              title='Managing >200,000 sq ft'
              description='of Container Freight Stations worldwide'
              icon={SquareArea}
            />
            <AboutUsFeaturesTwo
              title='5000cbms'
              description='monthly cargo volume'
              icon={ContainerView}
            />
          </Stack>
        </Grid>
        <Grid
          item xs={12} md={6}
          sx={{
            display: {
              xs: 'none',
              sm: 'block', // Small screen or bigger, display block
            },
          }}
        >
          {MovintTruckView}
          {View}
        </Grid>
        <Grid
          item xs={12} md={6}
          sx={{
            display: {
              xs: 'block',
              sm: 'none', // Small screen or bigger, display block
            },
          }}
        >
          {MovintTruckView}
        </Grid>
      </Grid>
    </ImageBackgroundContent>
    </ImageBackground>
  );
};

const AboutUsFeaturesTwo: React.FC<{title: string; description: string; icon: any;}> = ({
  title,
  description,
  icon,
}) => {
  return (
    <div style={{ textAlign: 'center', height: '50%' }}>
      {icon}
      <Typography variant='body1' fontWeight='bold' color='secondary'>{title}</Typography>
      <Typography variant='body1' color='secondary'>{description}</Typography>
    </div>
  );
};

const ContactUsSection: React.FC = () => {
  return (
    <ImageBackground url={FooterBackground} opacity={1} backgroundColor='rgba(255,255,255,0.7)'>
      <ImageBackgroundContent textAlign="center" p={4}>
        <SectionTitle title='Contact Us' />
      </ImageBackgroundContent>
    </ImageBackground>
  );
};

const ImageGallerySection = () => {
  const imageList = [{
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  }, {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  }, {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  }, {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    cols: 2,
  }, {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    cols: 2,
  }, {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  }, {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  }, {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  }, {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    rows: 2,
    cols: 2,
  }, {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  }, {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  }, {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    cols: 2,
  }];
  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  };
  return (
    <div>
      <ImageList
        sx={{ width: '100%', height: '350px' }}
        variant="quilted"
        cols={4}
        rowHeight={121}
      >
        {
          imageList.map((item) => (
            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
              <img
                {...srcset(item.img, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))
        }
      </ImageList>
    </div>
  );
};

// const Div = styled("div")(({ ownerState: { primary } }) => ({
//   backgroundColor: primary ? "palevioletred" : "white",
//   color: primary ? "white" : "palevioletred",
// }));

const ImageBackground = styled(Box)(({ url, opacity, backgroundColor }: { url: string; opacity: number; backgroundColor?: string }) => ({
  position: 'relative',
  minHeight: '400px', // Set the minimum height of the section to ensure it's visible
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundImage: `linear-gradient(to bottom, rgba(255, 165, 0, 0.5), rgba(255, 165, 0, 0.5)), url(${myImage})`
    backgroundImage: `
      ${backgroundColor
        ? `linear-gradient(to bottom, ${backgroundColor}, ${backgroundColor})`
        : 'linear-gradient(to bottom, rgba(255, 165, 0, 0), rgba(255, 165, 0, 0))'
      }, url(${url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity, // Set the opacity of the background image to 0.5
  },
}));

const ImageBackgroundContent = styled(Box)({
  position: 'relative',
  zIndex: 1,
  color: '#fff', // Set the text color to white to ensure it's visible on the background
});

const AboutUsSection: React.FC = () => {
  const imageList = [{
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  }, {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  }, {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  }, {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    cols: 2,
  }, {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    cols: 2,
  }, {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  }, {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  }, {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  }, {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    rows: 2,
    cols: 2,
  }, {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  }, {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  }, {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    cols: 2,
  }];
  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  };
  return (
    <ImageBackground url={WorldConnection} opacity={0.3}>
      <ImageBackgroundContent textAlign="center" p={4}>
        <Grid container>
          <Grid item xs={12} md={6} p={2}>
            <SectionTitle title='About Us' />
            <Typography color='primary.main'>
            We strive to bring customized solutions to our vast client base. JNC Line commits to develop into a Premier International NVOCC and Logistics Solutions Provider. We continue to invest in innovation processes, technology, training, equipments and other tools of support in order to provide valued added services to suit the ever changing and growing needs of our customers regionally and worldwide.
            </Typography>
            <Stack
            direction='row'
            spacing={10}
            // justifyContent='space-between'
            justifyContent='center'
            sx={{ paddingTop: '25px'}}
          >
            <AboutUsFeatures
              title='>200,000 sq ft'
              description='of Container Freight Stations'
              icon='/FloorSpacePicTransparent.png'
            />
            <AboutUsFeatures
              title='Managing >200,000 sq ft'
              description='of Container Freight Stations'
              icon='/FloorSpacePicTransparent.png'
            />
          </Stack>
          </Grid>
          <Grid item xs={12} md={6} p={2}>
            <ImageList
              sx={{ width: '100%', height: '350px' }}
              variant="quilted"
              cols={4}
              rowHeight={121}
            >
              {
                imageList.map((item) => (
                  <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                    <img
                      {...srcset(item.img, 121, item.rows, item.cols)}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))
              }
            </ImageList>
          </Grid>
        </Grid>
      </ImageBackgroundContent>
    </ImageBackground>
  );
};

const AboutUsFeatures: React.FC<{title: string; description: string; icon: string;}> = ({
  title,
  description,
  icon,
}) => {
  return (
    <div style={{ textAlign: 'center', height: '50%' }}>
      <Box
        component="img"
        // justifyItems='center'
        sx={{
          width: '50px',
          height: '50px',
          objectFit: 'cover',
          // objectFit: 'contain',
          // maxHeight: { xs: 50, md: 50 },
          // maxWidth: { xs: 50, md: 50 },
          // display: {
          //   xs: 'flex',
          //   md: 'none', // Small screen or bigger, display block
          // },
        }}
        // alt="logo"
        src={icon}
      />
      <Typography variant='body1' fontWeight='bold' color='secondary'>{title}</Typography>
      <Typography variant='body1' color='secondary'>{description}</Typography>
    </div>
  );
};

const VideoIntroduction: React.FC = () => {
  return (
    <Box component='section'>
      <div style={{ width: '100%' }}>
        {/* <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,.2)',
          }}
        /> */}
        <div
          style={{
            width: '100%',
            height: '65vh'
          }}
        >
          <Box
            component="video"
            justifyItems='center'
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              // objectFit: 'contain',
              // maxHeight: { xs: 50, md: 50 },
              // maxWidth: { xs: 50, md: 50 },
              // display: {
              //   xs: 'flex',
              //   md: 'none', // Small screen or bigger, display block
              // },
            }}
            // alt="logo"
            src="https://video.wixstatic.com/video/b759c6_b664192f9cc84c52adb5d8a935a2c2e5/1080p/mp4/file.mp4"
            // controls
            muted
            autoPlay={true}
            loop={true}
          />
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            <h1>Comprehensive, Reliable Freight Services</h1>
          </div>
        </div>
      </div>
    </Box>
  );
};

const HowToUseSection: React.FC = () => {
  return (
    <div style={{ width: '100%', paddingBottom: '30px' }}>
      <SectionTitle title='Ship with convienience!' />
      <Grid container p={2} spacing={2}>
        <Grid item xl={4} md={4} xs={12}>
          <HowToUseCard
            title='Step 1'
            description='Shop from any China/US sites, and ship to our warehouse'
            icon={(
              <ShoppingBasket
                sx={{
                  border: 'solid 2px',
                  borderRadius: '50%',
                  borderColor: 'primary.main',
                  padding: '20px',
                }}
                fontSize='large'
                color='secondary'
              />
            )}
          />
        </Grid>
        <Grid item xl={4} md={4} xs={12}>
          <HowToUseCard
            title='Step 2'
            description='Submit your tracking number via JNC Logistics'
            icon={(
              <Warehouse
                sx={{
                  border: 'solid 2px',
                  borderRadius: '50%',
                  borderColor: 'primary.main',
                  padding: '20px',
                }}
                fontSize='large'
                color='secondary'
              />
            )}
          />
        </Grid>
        <Grid item xl={4} md={4} xs={12}>
          <HowToUseCard
            title='Step 3'
            description='Select shipping method & we ship your parcels to you!'
            icon={(
              <LocalShipping
                sx={{
                  border: 'solid 2px',
                  borderRadius: '50%',
                  borderColor: 'primary.main',
                  padding: '20px',
                }}
                fontSize='large'
                color='secondary'
              />
            )}
          />
        </Grid>
      </Grid>
      <Stack
        direction='row'
        spacing={2}
        // justifyContent='space-between'
        justifyContent='center'
      >
        <Button variant='contained' color='secondary' sx={{ color: 'white' }}>Submit Order</Button>
        <Button variant='outlined'  color='secondary'>View Order</Button>
      </Stack>
    </div>
  );
};

const HowToUseCard: React.FC<{ title: string; description: string; icon: JSX.Element }> = ({
  title,
  description,
  icon,
}) => {
  return (
    <div style={{ }}>
      <Stack
        direction='row'
        // spacing={2}
        // justifyContent='space-between'
        // justifyContent='center'
      >
       {icon}
        <div style={{ padding: '10px' }}>
          <Typography variant='body1' fontWeight='bold' color='primary.main'>{title}</Typography>
          <Typography variant='body1' color='primary.main'>{description}</Typography>
        </div>
      </Stack>
    </div>
  );
};

const OurServicesSection: React.FC = () => {
  return (
    <div style={{ width: '100%' }}>
      <SectionTitle title='Our Services' />
      <Grid container p={2} spacing={2}>
        <Grid item xl={3} md={3} xs={6}>
          <OurServicesCard
            title='Air Freight'
            description='JNC Air Freight Caters to both local and overseas clients'
            image='https://static.wixstatic.com/media/23e543_97180aeb5f0e4061bd8e580e08ed981c~mv2.jpg/v1/fill/w_512,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_97180aeb5f0e4061bd8e580e08ed981c~mv2.jpg'
          />
        </Grid>
        <Grid item xl={3} md={3} xs={6}>
          <OurServicesCard
            title='Cold Chain'
            description='A one-stop service centre, for all food chain'
            image='https://static.wixstatic.com/media/23e543_bea5eb0f05544e649ab5aafabbb067d5~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_bea5eb0f05544e649ab5aafabbb067d5~mv2.jpg'
          />
        </Grid>
        <Grid item xl={3} md={3} xs={6}>
          <OurServicesCard
            title='E-Commerce'
            description='JNC Air Freight Caters to both local and overseas clients.'
            image='https://static.wixstatic.com/media/b759c6_0c6f6f2d8080424ba177b2f15bfeb2e0~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/b759c6_0c6f6f2d8080424ba177b2f15bfeb2e0~mv2.jpg'
          />
        </Grid>
        <Grid item xl={3} md={3} xs={6}>
          <OurServicesCard
            title='Flexi-Bag'
            description='Flexi-bags (also called flexi-tanks) offer an alternative to ISO tank containers'
            image='https://static.wixstatic.com/media/bb6119_1212d68ef4db45428a36525d4b31f22c~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/bb6119_1212d68ef4db45428a36525d4b31f22c~mv2.jpg'
          />
        </Grid>
        <Grid item xl={3} md={3} xs={6}>
          <OurServicesCard
            title='ISO tank'
            description='Has more than 500 own ISO Tank'
            image='https://static.wixstatic.com/media/23e543_94d7458e926049879b76ed44edf65080~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_94d7458e926049879b76ed44edf65080~mv2.jpg'
          />
        </Grid>
        <Grid item xl={3} md={3} xs={6}>
          <OurServicesCard
            title='LCL Consolidation'
            description='LCL Consolidation to more than 50 ports destinations'
            image='https://static.wixstatic.com/media/4715bd_40fb51231f884843836857eb63cd754a~mv2.jpeg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/4715bd_40fb51231f884843836857eb63cd754a~mv2.jpeg'
          />
        </Grid>
        <Grid item xl={3} md={3} xs={6}>
          <OurServicesCard
            title='Project Cargoes'
            description='For larger cargo transportation, JNC offers shipping for all categories'
            image='https://static.wixstatic.com/media/23e543_e3e226f828994283b48b2c63713aa5fd~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_e3e226f828994283b48b2c63713aa5fd~mv2.jpg'
          />
        </Grid>
        <Grid item xl={3} md={3} xs={6}>
          <OurServicesCard
            title='SOC Operation'
            description='More than 1000 own containers'
            image='https://static.wixstatic.com/media/bb6119_92e752ac552e470eaa83732ecc9bfe7f~mv2.png/v1/fill/w_396,h_340,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/bb6119_92e752ac552e470eaa83732ecc9bfe7f~mv2.png'
          />
        </Grid>
        <Grid item xl={3} md={3} xs={6}>
          <OurServicesCard
            title='Ship Agencies'
            description='As booking agent for APS'
            image='https://static.wixstatic.com/media/23e543_bfc6940e019d4f1ea8388d8b4804a6fa~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_bfc6940e019d4f1ea8388d8b4804a6fa~mv2.jpg'
          />
        </Grid>
        <Grid item xl={3} md={3} xs={6}>
          <OurServicesCard
            title='Special Project Cargo'
            description='We specialise in the handling and storage for your special cargo'
            image='https://static.wixstatic.com/media/23e543_1b56d0f2555046c6b223beb7a5973b5f~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_1b56d0f2555046c6b223beb7a5973b5f~mv2.jpg'
          />
        </Grid>
      </Grid>
    </div>
  );
};

const OurServicesCard: React.FC<{ title: string; description: string; image: string; }> = ({
  title,
  description,
  image,
}) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        cursor: 'pointer',
      }}
    >
      <CardMedia
        sx={{ minHeight: 170, height: '100%', objectFit: 'contain' }}
        image={image}
        title={title}
      />
      <CardContent
        sx={{
          textAlign: 'center',
          backgroundColor: 'primary.main',
          // height: {
          //   md: '85px',
          //   // xs: '',
          // },
        }}
      >
        <Typography gutterBottom variant="h5" component="div" fontWeight='bold' color='secondary'>
          {title}
        </Typography>
        <Typography variant="body2" color='#FFF'>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const LocationSection: React.FC = () => {
  return (
    <div style={{ width: '100%' }}>
      <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.344914609546!2d103.8286041!3d1.271294!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da196749c227d1%3A0xefb8c282c9d8b53!2sJNC%20Line%20(S)%20Pte%20Ltd.!5e0!3m2!1sen!2ssg!4v1683124276573!5m2!1sen!2ssg"
        width="100%"
        height="200px"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
      ></iframe>
    </div>
  );
};

const SectionTitle: React.FC<{ title: string }> = ({
  title,
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        alignContent: 'center',
        padding: '20px 0'
      }}
    >
      <Typography variant='h5' p={1} color='primary'>{title}</Typography>
      <Divider
        sx={{
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.main,
          width: '10%',
          minWidth: '30px',
          height: '5px',
          textAlign: 'center',
          margin: 'auto'
        }}
      />
    </div>
  );
};
