import { Grid, Typography } from '@mui/material';
import { Switch, SwitchProps } from 'formik-mui';

interface FormSwitchInterface extends SwitchProps {
  label: string;
}

const FormSwitch = (props: FormSwitchInterface) => {
  const { required, ...rest } = props;
  const label = required ? `${props.label} *` : props.label;

  return (
    <Grid container>
      <Grid item xl={11} md={11} xs={11}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xl={1} md={1} xs={1} sx={{ textAlign: 'right' }}>
        <Switch
          {...rest}
        />
      </Grid>
    </Grid>
  );
};

export default FormSwitch;
