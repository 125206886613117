import React, { useState } from 'react';
import {
  styled, Typography, Box, Stack,
  AppBar, Toolbar, InputBase, Badge, Avatar,
  Menu, MenuItem,
  List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, Checkbox,
} from '@mui/material';
import { Pets, Mail, Notifications, Home, Brightness4, FavoriteBorder, MoreVert, Favorite, Share, FavoriteOutlined } from '@mui/icons-material'

const SampleFlex: React.FC = () => {

  return (
    <div>
      <Typography variant='h5'>Sample Flex</Typography>
      <Box>
        <Navbar />
        <Stack
          direction='row'
          // spacing={2}
          justifyContent='space-between'
          // justifyContent='center'
        >
          <Sidebar />
          <Feed />
          <Rightbar />
        </Stack>
      </Box>
    </div>
  );
};

export default SampleFlex;

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const Search = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  padding: '0 10px',
  borderRadius: theme.shape.borderRadius,
  width: '40%',
}));

const Icons = styled(Box)(({ theme }) => ({
  // backgroundColor: 'white',
  display: 'none',
  gap: '20px',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));

const UserBox = styled(Box)(({theme}) => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
 
const Navbar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position='sticky'
      // position='static'
    >
      <StyledToolbar>
        <Typography
          variant='h6'
          sx={{
            display:{
              xs: 'none',
              sm: 'block',
            },
          }}
        >
          Lama Dev
        </Typography>
        <Pets
          sx={{
            display:{
              xs: 'block',
              sm: 'none',
            },
          }}
        />
        <Search>
          <InputBase placeholder='Search' />
        </Search>
        <Icons>
          <Badge badgeContent={4} color='error'>
            <Mail color='action' />
          </Badge>
          <Badge badgeContent={2} color='error'>
            <Notifications color='action' />
          </Badge>
          <Avatar
            sx={{ width: 30, height: 30 }}
            onClick={handleClick}
          >
            HL
          </Avatar>
        </Icons>
        <UserBox>
          <Avatar
            sx={{ width: 30, height: 30 }}
            onClick={handleClick}
          >
            HL
          </Avatar>
          <Typography component='span'>John</Typography>
        </UserBox>
      </StyledToolbar>
      {/* Menu */}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem>Profile</MenuItem>
        <MenuItem>My account</MenuItem>
        <MenuItem>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
};

const Sidebar: React.FC = () => {
  return (
    <Box
      // bgcolor='skyblue'
      flex={1}
      p={2}
      sx={{
        display: {
          xs: 'none',
          sm: 'block', // Small screen or bigger, display block
        },
      }}
    >
      <Box position='fixed'>
        <List>
          <ListItem disablePadding>
            <ListItemButton component='a' href='/home'>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Homepage" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component='a' href='/settings'>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component='a' href='/home'>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component='a' href='/home'>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Friengs" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component='a' href='/home'>
              <ListItemIcon>
                <Brightness4 />
              </ListItemIcon>
              {/* <ListItemText primary="Friengs" /> */}
              <Switch />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

const Cards: React.FC = () => {
 return (
    <Card
      sx={{
        // maxWidth: 345,
        marginBottom: '10px'
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{
              bgcolor: 'red',
              // bgcolor: red[500],
            }}
            aria-label="recipe"
          >
            R
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVert />
          </IconButton>
        }
        title="Shrimp and Chorizo Paella"
        subheader="September 14, 2016"
      />
      <CardMedia
        component="img"
        height="20%"
        image="https://www.ship-technology.com/wp-content/uploads/sites/8/2020/10/Feature-Image-Top-10-Shipping-Companies.jpg"
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the mussels,
          if you like.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
          <Favorite />
        </IconButton> */}
        <Checkbox
          icon={<FavoriteBorder />}
          checkedIcon={<Favorite sx={{ color: 'red' }} />}
        />
        <IconButton aria-label="share">
          <Share />
        </IconButton>
      </CardActions>
    </Card>
 );
};

const Feed: React.FC = () => {
  return (
    <Box
      // bgcolor='pink'
      flex={4}
      p={2}
    >
      <Cards />
      <Cards />
      <Cards />
    </Box>
  );
};

const Rightbar: React.FC = () => {
  return (
    <Box
      // bgcolor='lightcoral'
      flex={2}
      p={2}
      sx={{
        display: {
          xs: 'none',
          sm: 'block', // Small screen or bigger, display block
        },
      }}
    >
      <Box position='fixed'>
        Rightbar
      </Box>
    </Box>
  );
};
