import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button, Grid, } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import ContextApp from '../../../contexts/ContextApp';
import { authenticate, isAuthenticated } from '../../helpers/authentication';
import FormTextField from '../../utility/forms/FormTextField';

const LoginPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const contextApp = useContext(ContextApp);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    let isUnmounted = false;
    const authenticated = isAuthenticated(contextApp);
    // Set workspace from localstorage
    if (authenticated) {
      const from = (location.state as any || {}).from;
      const redirectTo = !from || from === '/' ? '/' : from;
      navigate(redirectTo);
    }

    // window.localStorage.setItem('activews', contextApp.)
    const auth = async () => {
      const err = await authenticate(contextApp);
      if (!isUnmounted) setIsLoading(err === null);
    }

    if (!authenticated) auth();

    return () => { isUnmounted = true; }
  }, [contextApp, location.state, navigate]);

  const schema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{  
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Formik
            enableReinitialize
            validationSchema={schema}
            initialValues={{
              username: '',
              password: '',
            }}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(true);
              setErrorMessage('');
              const error = await authenticate(contextApp, {
                username: values.username,
                password: values.password,
              });
              if (error) {
                setErrorMessage(error.message);
              }
              actions.setSubmitting(false);
            }}
          >
            {({
              isSubmitting,
              errors,
              submitForm,
              dirty,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              setTouched,
              resetForm,
            }) => (
              <Form>
                <Field
                  component={FormTextField}
                  name='username'
                  type='text'
                  label='Username'
                  required
                />
                <Field
                  component={FormTextField}
                  name='password'
                  type='password'
                  label='Password'
                  required
                />
                <Box sx={{ width: '100%', paddingBottom: 1, textAlign: 'center' }}>
                  <Typography variant='caption' color='red'>
                  {errorMessage}
                  </Typography>
                </Box>
                <Button
                  variant='contained'
                  sx={{ width: '100%' }}
                  type='submit'
                  disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
                >
                  SIGN IN
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
        <Grid container>
            <Grid item xs>
              <Button variant='text'>
                Forgot password?
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                onClick={() => {
                  navigate('/signup');
                }}
              >
                Sign Up
              </Button>
            </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default LoginPage;
