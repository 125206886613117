import { TextField, TextFieldProps } from 'formik-mui';

interface FormTextFieldInterface extends TextFieldProps {
  required: boolean;
}

const FormTextField = (props: FormTextFieldInterface) => {
  const { required, ...rest } = props;
  const label = required ? `${props.label} *` : props.label;
  return (
    <TextField
      label={label}
      // variant='standard'
      variant='outlined'
      InputLabelProps={{
        shrink: true,
      }}
      // InputProps={{
      //   fontSize: '40px',
      // }}
      sx={{ width: '100%', marginBottom: 2 }}
      size='small'
      {...rest}
    />
  );
};

export default FormTextField;
