import { useEffect, useState } from 'react';
import { workplaceFind } from '../../../feathers/services/Workspace.service';

import { Workspace } from '../../../feathers/server-interface';

const useFetchWarehousesByType = (type: 'forwarding' | 'destination') => {
  const [warehouses, setWarehouses] = useState<Workspace[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const fetchedWarehouses = await workplaceFind(type);
        setWarehouses(fetchedWarehouses); 
      } catch (error: any) {
        setError(error.message);
      }
      setIsLoading(false);
    }
    init();
  }, [type]);

  return {
    warehouses,
    isLoading,
    error,
  };
};

export default useFetchWarehousesByType;
